@use '../node_modules/@angular/material' as mat;


@include mat.core();



$cb-palette: ( 50 : #e6e6ed, 100 : #c0bfd3, 200 : #9695b5, 300 : #6b6b97, 400 : #4c4b81, 500 : #2c2b6b, 600 : #272663, 700 : #212058, 800 : #1b1a4e, 900 : #10103c, A100 : #7878ff, A200 : #4545ff, A400 : #1212ff, A700 : #0000f7, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #ffffff, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #ffffff, A400 : #ffffff, A700 : #ffffff, ) );
$cb-accent-palette: ( 50 : #E1E2E1, 100 : #cfd5dc, 200 : #afb9c4, 300 : #8da0b9, 400 : #77879b, 500 : #5f7289, 600 : #576a81, 700 : #4d5f76, 800 : #43556c, 900 : #34475c, A100 : #a8cbff, A200 : #75acff, A400 : #428dff, A700 : #297dff, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #000000, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #000000, A700 : #ffffff, ) );
$cb-primary: mat.m2-define-palette($cb-palette, 500);
$cb-accent: mat.m2-define-palette($cb-accent-palette, A200, A100, A400);

$custom-typography: mat.m2-define-typography-config($font-family: '"Open Sans", Roboto, "Helvetica Neue", sans-serif');

$cb-theme: mat.m2-define-light-theme((
    color: (
        primary: $cb-primary,
        accent: $cb-accent,
    ),
    typography: $custom-typography,
    density: -2, ));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($cb-theme); 

/*
contrast #E1E2E1
secondary #ffffff
primaryColor #2c2b6b
primaryLightColor #5b549a
primaryDarkColor #00023f
text on primary #ffffff
text on secondary #000000

https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=262261&secondary.color=ffffff&secondary.text.color=000000
*/
