$beige-grey-colour: #CCCBC6;
$dark-grey-colour: #6c757d;
$grey-colour: #A0A6AB;
$light-grey-colour: #ebebeb;


$cb-pink-colour: #ef65a4;
$cb-dark-pink-colour: #e0216e;
$cb-blue-colour: #58c8e9;
$cb-dark-blue-colour: #5076b3;
$cb-purple-colour: #705099;
$cb-dark-purple-colour: #5b2a86;
$cb-turquoise-colour: #92d2ce;
$cb-dark-turquoise-colour: #71c8d9;


$nav-bar-background-colour: #2c2b6b;
$card-header-background-colour: #2c2b6b;
$listbox-header-background-colour: $dark-grey-colour;
$button-background-colour: #2c2b6b;
$valid-colour: #42A948;
$invalid-colour: #a94442;

$scrollbar-colour: #c0bfd3;
$admin-header-background-colour: $dark-grey-colour;
$box-border-colour: #c0c0c0;
